<template>
  <b-modal
    id="upsert-advertiser-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="reset"
    @shown="shown"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ title }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <form @submit.prevent="submit" class="text-left default-form">
      <div class="form-group mb-3 mt-3">
        <label class="form-label fw-bold">{{ $t('advertiser_location') }}</label>
        <div class="custom-select">
          <select class="form-control" v-model.trim="$v.form.country.$model">
            <option disabled selected value="">{{ $t('select') }}</option>
            <option v-for="(country, index) in parsedCountries" :key="index" :value="country.code"  :selected="country.code === form.country">{{ country.name }}</option>
          </select>
        </div>
      </div>
      <template v-if="form.country">
        <div class="form-group mb-3">
          <label class="form-label fw-bold">{{ $t('name') }}</label>
          <input type="text" class="form-control" :placeholder="$t('name')" v-model.trim="$v.form.name.$model" />
        </div>
        <div class="form-group mb-3" v-if="form.country === 'BR'">
          <label class="form-label fw-bold">{{ $t('company_personal_id') }}*</label>
          <the-mask class="form-control" :placeholder="$t('company_personal_id')+'*'" :mask="['###.###.###-##', '##.###.###/####-##']" v-model.trim="$v.form.advertiser_document.$model" />
        </div>
        <div class="form-group mb-3" v-else>
          <label class="form-label fw-bold">{{ $t('company_tax_id') }}*</label>
          <input type="text" class="form-control" :placeholder="$t('company_tax_id')+'*'" v-model.trim="$v.form.advertiser_document.$model" />
        </div>
        <div class="form-group mb-3"  v-if="form.country === 'BR'">
          <label class="form-label fw-bold">
            {{ $t('billing_profile') }}*
            <span v-b-tooltip.hover :title="tooltips.billing_profile"><i class="fa fa-info-circle"></i></span>
          </label>
          <div class="custom-select">
            <select class="form-control" v-model.trim="form.billing_profile">
              <option value="ADVERTISER">{{ $t('advertiser') }}</option>
              <option value="COMPANY">{{ $t('company') }}</option>
            </select>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold">
            {{ $t('currency') }}*
            <span v-b-tooltip.hover :title="tooltips.currency"><i class="fa fa-info-circle"></i></span>
          </label>
          <div class="custom-select">
            <select class="form-control" v-model.trim="$v.form.currency.$model" :disabled="advertiser">
              <option value="BRL">Real</option>
              <option value="EUR">Euro</option>
              <option value="USD">Dolar</option>
            </select>
          </div>
          <!-- MESSAGES -->
          <div
            class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2 mt-2"
            v-if="form.currency"
          >
            <p
              class="mb-0"
            >
              {{ $t('currency_warning') }}
            </p>
          </div> <!-- END MESSAGES -->
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold">{{ $t('website') }}*</label>
          <div class="input-group mb-3">
            <span class="input-group-text">https://</span>
            <input type="text" class="form-control" placeholder="empresa.com.br" v-model.trim="$v.form.website.$model" />
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold">{{ $t('category') }}</label>
          <div v-if="categoriesLoader">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </div>
          <div class="custom-select" v-else>
            <select class="form-control" v-model.trim="$v.form.category_id.$model">
              <option disabled="true" selected="true">{{ $t('select') }}</option>
              <template v-if="categories.length > 0">
                <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ $t('categories.' + category.slug) }}</option>
              </template>
            </select>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold">{{ $t('logo') }}</label><br/>
          <input type="file" @change="changeFile" accept="image/*" />
          <p class="font-size-xSmall">{{ $t('only_png_or_jpg_images_up_to_200kb') }}</p>
        </div>
        <div class="mb-3" v-if="form.country === 'BR'">
          <div class="position-relative">
            <LoaderOverlay
              size="big"
              v-if="zipcodeLoader"
            />
            <div
              class="d-lg-flex mb-3"
            >
              <div class="col-lg-4 pl-0">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('zipcode') }}*</label>
                  <TheMask
                    :mask="'#####-###'"
                    v-model.trim="$v.form.address.zipcode.$model"
                    placeholder=""
                    class="form-control"
                    @change.native="getZipcode"
                  />
                </div>
              </div>
              <div class="col-lg-8 px-0 ps-lg-3">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('street') }}*</label>
                  <input type="text" class="form-control" v-model.trim.trim="$v.form.address.street.$model" placeholder="" :readonly="readonlyForm" />
                </div>
              </div>
            </div>
            <div
              class="d-lg-flex mb-3"
            >
              <div class="col-lg-4 pl-0">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('number') }}*</label>
                  <input type="text" class="form-control" v-model.trim.trim="$v.form.address.number.$model" placeholder="" />
                </div>
              </div>
              <div class="col-lg-4 pl-0 px-lg-3">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('complement') }}</label>
                  <input type="text" class="form-control" v-model.trim.trim="form.address.complement" placeholder="" />
                </div>
              </div>
              <div class="col-lg-4 px-0">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('neighborhood') }}*</label>
                  <input type="text" class="form-control" v-model.trim.trim="$v.form.address.neighborhood.$model" placeholder="" :readonly="readonlyForm" />
                </div>
              </div>
            </div>
            <div
              class="d-lg-flex"
            >
              <div class="col-lg-5 pl-0 pe-lg-3">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('city') }}*</label>
                  <input type="text" class="form-control" v-model.trim.trim="$v.form.address.city.$model" placeholder="" readonly />
                </div>
              </div>
              <div class="col-lg-5 px-0 pe-lg-3">
                <div class="form-group">
                  <label class="form-label fw-bold">{{ $t('state') }}*</label>
                  <input type="text" class="form-control" v-model.trim.trim="$v.form.address.state.$model" placeholder="" readonly />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="text-center mt-3">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            {{ $t('this_action_may_take_a_few_minutes') }}
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="submit"
          class="btn btn-primary"
          :disabled="$v.$invalid"
          :loading="loader"
        >
          {{ buttonText }}
        </Button>
      </div>
    </form>
  </b-modal>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CategoriesService from '@/modules/categories/services/categories-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { getZipcodeData, parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'
import variables from '@/mixins/variables'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'UpsertAdvertiserModal',
  mixins: [variables],
  props: ['advertiser'],
  components: {
    AlertWraper,
    Button,
    Loader,
    LoaderOverlay,
    TheMask
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      categoriesLoader: false,
      categories: [],
      form: {
        type: '',
        name: '',
        advertiser_document: '',
        billing_profile: 'ADVERTISER',
        currency: '',
        website: '',
        category_id: '',
        logo: '',
        country: '',
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      loader: false,
      readonlyForm: true,
      zipcodeLoader: false
    }
  },
  validations () {
    const form = {
      name: { required },
      advertiser_document: { required },
      currency: { required },
      website: { required },
      category_id: { required },
      country: { required }
    }
    if (this.form.country === 'BR') {
      form.address = {
        zipcode: { required },
        street: { required },
        number: { required },
        neighborhood: { required },
        city: { required },
        state: { required }
      }
    }
    return {
      form: form
    }
  },
  computed: {
    buttonText () {
      return this.advertiser ? this.$t('save') : this.$t('create_advertiser')
    },
    title () {
      return this.advertiser ? this.$t('edit_advertiser') : this.$t('create_advertiser')
    },
    /**
     * parse countries list
    */
    parsedCountries () {
      const countries = this.countries.map(item => {
        return {
          code: item,
          name: this.$t(`countries_names.${item}`)
        }
      })

      countries.sort((a, b) => a.name.localeCompare(b.name))

      return countries
    }
  },
  methods: {
    /**
     * Handle change on file
     */
    changeFile (e) {
      const file = e.target.files[0]
      this.form.logo = file
    },
    /**
     * Get categories
     */
    async getCategories () {
      this.categoriesLoader = true
      this.content = null
      try {
        const categories = await CategoriesService.getCategories({ type: 'ADVERTISER' })
        this.categories = categories.data
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.categoriesLoader = false
    },
    /**
     * Get zipcode data given a zipcode
     */
    async getZipcode () {
      this.zipcodeLoader = true
      this.content = null
      try {
        const address = await getZipcodeData(this.form.address.zipcode)
        this.$v.form.address.street.$model = address.street
        this.$v.form.address.neighborhood.$model = address.neighborhood
        this.$v.form.address.city.$model = address.city
        this.$v.form.address.state.$model = address.state

        this.readonlyForm = !!address.street
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.zipcodeLoader = false
    },
    reset () {
      this.form.country = ''
      this.form.name = ''
      this.form.website = ''
      this.form.advertiser_document = ''
      this.form.billing_profile = 'ADVERTISER'
      this.form.currency = ''
      this.form.category_id = ''
      this.form.address.zipcode = ''
      this.form.address.street = ''
      this.form.address.number = ''
      this.form.address.complement = ''
      this.form.address.neighborhood = ''
      this.form.address.city = ''
      this.form.address.state = ''
      this.form.logo = ''
    },
    /**
     * Set advertiser data to form
     */
    setAdvertiser () {
      if (this.advertiser) {
        this.form.country = this.advertiser.country
        this.form.name = this.advertiser.name
        this.form.website = this.advertiser.website
        this.form.advertiser_document = this.advertiser.document
        this.form.billing_profile = this.advertiser.billing_profile
        this.form.currency = this.advertiser.currency
        this.form.category_id = this.advertiser.category.id
        if (this.advertiser.address) {
          this.form.address.zipcode = this.advertiser.address.zipcode
          this.form.address.street = this.advertiser.address.street
          this.form.address.number = this.advertiser.address.number
          this.form.address.complement = this.advertiser.address.complement
          this.form.address.neighborhood = this.advertiser.address.neighborhood
          this.form.address.city = this.advertiser.address.city
          this.form.address.state = this.advertiser.address.state
        }
        this.form.country = this.advertiser.country
      } else {
        this.form.country = ''
        this.form.name = ''
        this.form.website = ''
        this.form.advertiser_document = ''
        this.form.category_id = ''
      }
      // Get categories
      if (this.categories.length === 0) this.getCategories()
    },
    /**
     * On modal shown
     */
    shown () {
      this.setAdvertiser()
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        let advertiser = null
        const form = Object.assign({}, this.form)
        if (form.website) form.website = `https://${form.website.replace('https://', '').replace('http://', '')}`
        if (!this.advertiser) advertiser = await AdvertisersService.createAdvertiser(form)
        else advertiser = await AdvertisersService.updateAdvertiser(this.advertiser.id, form)
        const notification = {
          notification: {
            type: 'success',
            content: this.advertiser ? this.$t('advertiser_updated_successfully') : this.$t('advertiser_created_successfully')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('upsert-advertiser-modal')
        if (this.advertiser) this.$emit('advertiser-updated', advertiser)
        else this.$emit('advertiser-created', advertiser)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
